import da from '@/locales/lang/da.json'
import de from '@/locales/lang/de.json'
import enUS from '@/locales/lang/en-US.json'
import es from '@/locales/lang/es.json'
import fi from '@/locales/lang/fi.json'
import frCA from '@/locales/lang/fr-CA.json'
import frFR from '@/locales/lang/fr-FR.json'
import it from '@/locales/lang/it.json'
import nl from '@/locales/lang/nl.json'
import no from '@/locales/lang/no.json'
import ptBR from '@/locales/lang/pt-BR.json'
import ptPT from '@/locales/lang/pt-PT.json'
import sv from '@/locales/lang/sv.json'
import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en-US',
  globalInjection: true,
  legacy: false,
  runtimeOnly: true,
  datetimeFormats: {
    'en-US': {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    de: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    'fr-FR': {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    'fr-CA': {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    it: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    nl: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    'pt-PT': {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    'pt-BR': {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    es: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    sv: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    da: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    fi: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
    no: {
      short: { year: 'numeric', month: 'numeric', day: 'numeric' },
      long: { year: 'numeric', month: 'long', day: 'numeric' },
    },
  },
  messages: {
    'en-US': enUS,
    es,
    de,
    'fr-CA': frCA,
    'fr-FR': frFR,
    'pt-BR': ptBR,
    'pt-PT': ptPT,
    it,
    nl,
    sv,
    da,
    fi,
    no,
  },
})

export default i18n
