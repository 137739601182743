import { LocationService, PaymentService } from '@/services'

import { PaymentProviderType } from '@/types'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'

export type ThirdPartyProvider = 'stripe' | 'authorize-net' | 'nmi' | 'paypal'
export type ProviderType =
  | ThirdPartyProvider
  | PaymentProviderType.WHITE_LABEL
  | PaymentProviderType.CUSTOM_PROVIDER
export type WhiteLabelProvider = 'authorize-net' | 'nmi'
export interface Provider {
  provider: PaymentProviderType
  providerConfig: {
    test: any
    live: any
  }
  whiteLabelProvider?: WhiteLabelProvider
  uniqueName?: string
  title?: string
  name?: string
  description?: string
  imageUrl?: string
  providerId?: string
  meta?: {
    installed: boolean
    appId: string
  }
}

interface ConnectedProviders {
  providers: Provider[] // connected providers
  defaultPaymentProvider: Provider
  moreProviders?: Provider[] //more providers
}
interface Store {
  legacyStripeMode?: boolean
  connectedProviders?: ConnectedProviders
  loadingConnectedProviders: boolean
}
export const usePaymentIntegrations = defineStore('paymentIntegrations', {
  state: (): Store => ({
    legacyStripeMode: undefined,
    loadingConnectedProviders: false,
    connectedProviders: undefined,
  }),
  actions: {
    async getConnectedProviders(altId: string) {
      try {
        this.loadingConnectedProviders = true
        const { data } = await PaymentService.getConnectedProviders({
          altId,
          altType: 'location',
          with: 'moreProvider',
        })
        this.connectedProviders = data
      } catch (err) {
        console.error(err)
      } finally {
        this.loadingConnectedProviders = false
      }
    },
    async setLegacyStripeMode(altId: string) {
      const { data } = await LocationService.getLocationById(altId)
      this.legacyStripeMode =
        !!data?.location?.stripe?.publishableKey ||
        !!data?.location?.stripe?.publishable_key
    },
    isProviderConnected(provider: Provider, mode?: 'test' | 'live') {
      const hasNMIIntegration = (integration: {
        test: { merchantGatewayId: any }
        live: { merchantGatewayId: any }
      }) => {
        if (mode) return integration?.[mode]?.merchantGatewayId
        // nmi or authorize net
        return (
          integration?.test?.merchantGatewayId ||
          integration?.live?.merchantGatewayId
        )
      }

      const hasStripeIntegration = (integration: {
        test: { accountId: any }
        live: { accountId: any }
      }) => {
        if (mode) return integration?.[mode]?.accountId
        return integration?.test?.accountId || integration?.live?.accountId
      }

      const hasPaypalIntegration = (integration: any) => {
        const route = useRoute()
        const paypalVersion =
          (route?.query?.paypalVersion as unknown as number) || 1
        if (paypalVersion === 1) {
          if (mode) return integration?.[mode]?.merchantClientId
          return (
            integration?.live?.merchantClientId ||
            integration?.test?.merchantClientId
          )
        } else if (paypalVersion === 2) {
          if (mode) return integration?.[mode]?.merchantAccountId
          return (
            integration?.live?.merchantAccountId ||
            integration?.test?.merchantAccountId
          )
        }
        return false
      }

      const hasCustomProviderIntegration = (integration: any) => {
        if (mode) return integration?.[mode]?.publishableKey
        return (
          integration?.test?.publishableKey || integration?.live?.publishableKey
        )
      }
      const paymentProvider =
        provider.provider === PaymentProviderType.WHITE_LABEL
          ? provider.whiteLabelProvider
          : provider.provider
      let connectCheckFn
      if (
        [
          PaymentProviderType.NMI,
          PaymentProviderType.AUTHORIZE_NET,
          PaymentProviderType.SQUARE,
        ].includes(paymentProvider as PaymentProviderType)
      ) {
        connectCheckFn = hasNMIIntegration
      } else if (paymentProvider === PaymentProviderType.STRIPE) {
        connectCheckFn = hasStripeIntegration
      } else if (paymentProvider === PaymentProviderType.PAYPAL) {
        connectCheckFn = hasPaypalIntegration
      } else if (paymentProvider === PaymentProviderType.CUSTOM_PROVIDER) {
        connectCheckFn = hasCustomProviderIntegration
      }
      return connectCheckFn && connectCheckFn(provider.providerConfig)
    },
    isDefaultProvider(providerDetails: any, providerType: string) {
      const defaultProvider = providerDetails.defaultPaymentProvider
      if (providerType !== PaymentProviderType.WHITE_LABEL)
        return defaultProvider?.provider === providerType
      return providerDetails.provider._id === defaultProvider?.providerId
    },
  },
})
