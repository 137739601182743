<script setup lang="ts">
import { UINotification } from '@gohighlevel/ghl-canvas'
import { UIContentWrap } from '@gohighlevel/ghl-ui'
import Postmate from 'postmate'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useEmitter } from './composition/useEmitter'
import router from './router'
import { useMainStore } from './store/main'
import { EmitterKeys } from './types'
import { setupPendo } from './util/pendo_config'
import { setSentryMeta } from './util/setupSentry'
const i18n = useI18n()
const { t } = i18n
const eventBus = useEmitter()

const store = useMainStore()
const route = useRoute()
const fullScreenMode = computed(() =>
  ['create-product', 'edit-product', 'xero'].includes(route.name)
)
const authenticatedUser = ref(!!store.tokenId)
onMounted(() => {
  if (process.env.NODE_ENV === 'development') {
    Postmate.debug = false
  }
  const handshake = new Postmate.Model({
    'updated-token': (token: string) => store.refreshToken(token),
    'refresh-authorization-token': (authorization: string) =>
      store.refreshAuthToken(authorization),
    'selected-media-file': (data: any) => {
      eventBus.emit(EmitterKeys.SELECTED_MEDIA_FILE, data)
    },
    [EmitterKeys.EDIT_EMAIL_TEMPLATE_CLOSED]: async () => {
      eventBus.emit(EmitterKeys.REFRESH_INVOICE_SETTINGS)
    },
    'payment-method-link-copied-to-clipboard': (data: any) => {
      let type, text
      if (data.success) {
        type = 'success'
        text = 'Link copied'
      } else {
        type = 'danger'
        text = 'Unable to copy link. Try again'
      }
      UINotification({ type, text, position: data?.position })
    },
    're-routes': (data: any) => {
      router.push({
        path: data.route,
        query: data.query,
        params: data.params,
      })
    },
  })

  handshake.then(({ model }: any) => {
    store.setAppInitData(model)
    store.setAppHandshake(handshake)
    store.getCurrencyDetails()
    store.getCountryList()
    setupPendo({
      companyId: store.companyId,
      locationId: store.altId,
      userId: store.userId,
    })
    setSentryMeta({
      userId: store.userId,
      companyId: store.companyId,
      locationId: store.altId,
      email: store.userEmail,
      locale: i18n.locale.value,
    })
    setLocales(model.locale || 'en-US')
  })
})

function setLocales(lang: string) {
  let locale = lang
  locale = locale.replace('_', '-')
  localStorage.setItem('locales', locale)
  i18n.locale.value = locale
}

watch(
  () => store.tokenId,
  (tokenId: string) => {
    if (tokenId) {
      authenticatedUser.value = true
    } else {
      authenticatedUser.value = false
    }
  }
)
</script>

<template>
  <div id="main-app">
    <UIContentWrap :full-screen="fullScreenMode">
      <div v-if="authenticatedUser" class="app-container">
        <router-view :key="router.currentRoute.value.fullPath"></router-view>
      </div>
    </UIContentWrap>
  </div>
</template>

<style>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(209, 213, 219) !important;
  opacity: 1; /* Firefox */
}
.app-container {
  background: #f2f7fa;
}
</style>
