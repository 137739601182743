import {
  AuthorizeNetKeys,
  BusinessDetails,
  Country,
  CustomData,
  InvoiceSetting,
  NMIKeys,
  PaymentProviderType,
  SquareKeys,
  StripeKeys,
} from '@/types'
import CustomDataService, { Modules } from '@/services/CustomDataService'
import { ICustomField, ICustomValue } from '../types/index'
import { InvoiceService, LocationService } from '@/services'
import { Provider, usePaymentIntegrations } from './payment-integrations'

import { CurrencyOption } from '@/components/common/helper/InvoiceHelper'
import { PaymentService } from '@/services/PaymentService'
import StoreService from '@/services/StoreService'
import { defineStore } from 'pinia'

interface CurrencyDetails {
  currency: any
  currencyCode: object
}

interface Store {
  altId: string
  altType: string
  userEmail: string
  userId: string
  isAdminUser: boolean
  currency: string
  country: string
  tokenId: string
  authorization: string
  businessDetails: BusinessDetails
  currencyDetails: CurrencyDetails
  currencyOptions: CurrencyOption[]
  invoiceSetting: InvoiceSetting
  appHandshake: object | undefined
  retryCount: number
  timezone: string
  companyId: string
  stripeAccountConnected: undefined | boolean
  stripeKeys: undefined | StripeKeys
  hasUnsavedChanges: boolean
  isInternal: boolean
  authorizeAccountConnected: boolean | undefined
  defaultPaymentProvider: PaymentProviderType | undefined
  authorizeNetKeys: AuthorizeNetKeys | undefined
  getPaymentIntegrationLoading: boolean
  nmiAccountConnected: boolean
  nmiKeys: NMIKeys | undefined
  userPermissions: any
  isHLLocation: boolean
  customData: CustomData[]
  customFields: ICustomField[]
  customValues: ICustomValue[]
  levelUpFeatureActive: boolean
  levelUpDate: Date
  isInvoiceTemplateEnabled: boolean
  defaultPaymentProviderDetails: any
  countryList: Country[]
  squareKeys: SquareKeys | undefined
  onePaymentProviderConnected: { live: boolean; test: boolean }
}

const businessDetailsInitailValue = {
  name: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    countryCode: '',
    postalCode: '',
  },
  phoneNo: '',
  email: '',
  logoUrl: '',
  website: '',
}

export const useMainStore = defineStore('main', {
  state: (): Store => ({
    altId: (import.meta.env.VITE_ALT_ID as string) || '',
    altType: (import.meta.env.VITE_ALT_TYPE as string) || '',
    currency: (import.meta.env.VITE_CURRENCY as string) || '',
    userId: (import.meta.env.VITE_USER_ID as string) || '',
    userEmail: (import.meta.env.VITE_USER_EMAIL as string) || '',
    isAdminUser: false,
    tokenId: (import.meta.env.VITE_TOKEN_ID as string) || '',
    authorization: (import.meta.env.VITE_AUTHORIZATION as string) || '',
    country: (import.meta.env.VITE_COUNTY as string) || '',
    appHandshake: undefined,
    businessDetails: businessDetailsInitailValue as BusinessDetails,
    currencyDetails: {} as CurrencyDetails,
    invoiceSetting: {} as InvoiceSetting,
    currencyOptions: [],
    retryCount: 0,
    timezone: '',
    companyId: '',
    stripeAccountConnected: undefined,
    stripeKeys: undefined,
    hasUnsavedChanges: false,
    isInternal: false,
    authorizeAccountConnected: undefined,
    defaultPaymentProvider: undefined,
    authorizeNetKeys: undefined,
    getPaymentIntegrationLoading: true,
    nmiAccountConnected: false,
    nmiKeys: undefined,
    userPermissions: undefined,
    isHLLocation: false,
    customData: [] as CustomData[],
    customFields: [] as ICustomField[],
    customValues: [] as ICustomValue[],
    levelUpFeatureActive: false,
    levelUpDate: new Date('2024-10-22T10:00:00+05:30'),
    isInvoiceTemplateEnabled: false,
    defaultPaymentProviderDetails: undefined,
    countryList: [],
    squareKeys: undefined,
    onePaymentProviderConnected: { live: false, test: false },
  }),
  // optional getters
  getters: {
    currencySymbol: state => {
      if (state?.currency && state?.currencyDetails?.currency) {
        return (
          state?.currencyDetails?.currency[
            state.currency as keyof CurrencyDetails
          ]?.symbol || '$'
        )
      }
      return '$'
    },
    isLevelUpFeatureActive(state) {
      const now = new Date()
      const internalAgencies = [
        'YuTUZlUtrwBtvmgByZDW', //Production CompanyId,
        '5DP4iH6HLkQsiKESj6rh', //Staging CompanyId
        'ktapXmiRvMM4pJHyN3b3',
      ]
      const companyId = state.companyId
      if (companyId) {
        if (
          now >= state.levelUpDate ||
          internalAgencies.indexOf(companyId) > -1
        ) {
          if (!state.levelUpFeatureActive) {
            state.levelUpFeatureActive = true
          }
        }
        return state.levelUpFeatureActive
      } else {
        return false
      }
    },
  },
  // optional actions
  actions: {
    async getCurrencyDetails() {
      try {
        const { data } = await PaymentService.getCurrencyDetails()
        this.currencyDetails = data
        const currencyDetails = data.currency
        this.currencyOptions = Object.entries(currencyDetails).map(
          ([currencyCode, currencyData]: any) => {
            return { label: currencyData.code, value: currencyCode }
          }
        )
      } catch (error) {
        console.error(error)
      }
    },
    async getCountryList() {
      try {
        const { data } = await StoreService.getCountryList()
        this.countryList = data.data
      } catch (error) {
        console.error(error)
      }
    },
    async getBusinessDetails() {
      try {
        const { data } = await LocationService.getLocationById(this.altId)
        const { location } = data

        const { business } = location
        this.businessDetails.name = location.name
        this.businessDetails.phoneNo = location.phone
        this.businessDetails.website =
          business.website === 'https://' ? '' : business.website
        this.businessDetails.logoUrl = business.logoUrl
        this.businessDetails.address.addressLine1 = business.address
        this.businessDetails.address.countryCode = business.country
        this.businessDetails.address.state = business.state
        this.businessDetails.address.city = business.city
        this.businessDetails.address.postalCode = business.postalCode
      } catch (error) {
        console.error(error)
        return false
      }
    },
    async getStripeIntegration() {
      try {
        const { data } = await PaymentService.getStripeIntegration(this.altId)
        this.stripeAccountConnected = !!data
        this.stripeKeys = data
      } catch (error) {
        console.error(error)
      }
    },
    async getConnectedProviders() {
      try {
        const { data } = await PaymentService.getConnectedProviders({
          altId: this.altId,
          altType: 'location',
        })
        this.defaultPaymentProviderDetails = data.defaultPaymentProvider
        this.setPaymentKeys(data.defaultPaymentProvider)
        const paymentIntegrationStore = usePaymentIntegrations()
        this.onePaymentProviderConnected = {
          live: data.providers?.some((provider: Provider) =>
            paymentIntegrationStore.isProviderConnected(provider, 'live')
          ),
          test: data.providers?.some((provider: Provider) =>
            paymentIntegrationStore.isProviderConnected(provider, 'test')
          ),
        }
        return data.defaultPaymentProvider
      } catch (err) {
        console.error(err)
      }
    },
    setPaymentKeys(defaultPaymentProvider: any) {
      if (!defaultPaymentProvider?.provider) return
      if (
        defaultPaymentProvider.provider == PaymentProviderType.NMI ||
        (defaultPaymentProvider.provider === PaymentProviderType.WHITE_LABEL &&
          defaultPaymentProvider.whiteLabelProvider === PaymentProviderType.NMI)
      ) {
        this.nmiKeys = defaultPaymentProvider.providerConfig
      } else if (
        defaultPaymentProvider.provider == PaymentProviderType.AUTHORIZE_NET ||
        (defaultPaymentProvider.provider === PaymentProviderType.WHITE_LABEL &&
          defaultPaymentProvider.whiteLabelProvider ===
            PaymentProviderType.AUTHORIZE_NET)
      ) {
        this.authorizeNetKeys = defaultPaymentProvider.providerConfig
      } else if (
        defaultPaymentProvider.provider == PaymentProviderType.SQUARE
      ) {
        this.squareKeys = defaultPaymentProvider.providerConfig
      } else if (
        defaultPaymentProvider.provider == PaymentProviderType.STRIPE
      ) {
        this.stripeKeys = defaultPaymentProvider.providerConfig
      }
    },
    async getInvoiceSetting() {
      try {
        const { data } = await InvoiceService.getInvoiceSettings({
          altId: this.altId,
          altType: this.altType,
        })
        this.invoiceSetting = data
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomFields() {
      try {
        const { data } = await LocationService.getCustomFields(this.altId)
        this.customFields = data?.customFields
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomValuesAndData() {
      try {
        const invoiceCustomDataResponse =
          await new CustomDataService().getCustomValuesAndData(
            this.altId,
            Modules.Invoices
          )
        const estimateCustomDataResponse =
          await new CustomDataService().getCustomValuesAndData(
            this.altId,
            Modules.Estimates
          )
        if (invoiceCustomDataResponse) {
          this.customData = invoiceCustomDataResponse.static
          this.customValues = invoiceCustomDataResponse.customValues
        }
        if (estimateCustomDataResponse) {
          this.customData = this.customData.concat(
            estimateCustomDataResponse.static
          )
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getCustomFieldsNValues() {
      await Promise.allSettled([
        this.getCustomFields(),
        this.getCustomValuesAndData(),
      ])
    },
    setUnsavedChanges(payload: boolean) {
      this.hasUnsavedChanges = payload
      if (this.appHandshake) {
        this.appHandshake.then((parent: any) => {
          parent.emit('set-unsaved-changed', payload)
        })
      }
    },
    setAppHandshake(payload: any) {
      this.appHandshake = payload
    },
    setAppInitData(payload: any) {
      this.altId = payload.altId
      this.altType = payload.altType
      this.isAdminUser = payload.isAdminUser
      this.userId = payload.userId
      this.userEmail = payload.userEmail
      this.tokenId = payload.tokenId
      this.authorization = payload.authorization
      this.currency = payload.currency
      this.timezone = payload.timezone
      this.companyId = payload.companyId
      this.isInternal = [
        '5DP4iH6HLkQsiKESj6rh',
        'YuTUZlUtrwBtvmgByZDW',
        'ktapXmiRvMM4pJHyN3b3',
      ].includes(payload.companyId) // 5DP4iH6HLkQsiKESj6rh (staging) & YuTUZlUtrwBtvmgByZDW (production) & ktapXmiRvMM4pJHyN3b3(production)
      this.userPermissions = payload.userPermissions
      this.isHLLocation = [
        'knES3eSWYIsc5YSZ3YLl',
        'RnkghSOekXQvSqQBvu9Z',
      ].includes(payload.altId) // knES3eSWYIsc5YSZ3YLl (production) & RnkghSOekXQvSqQBvu9Z (staging)
      this.isInvoiceTemplateEnabled =
        payload.isInvoiceTemplateEnabled?.value === true
    },

    updateRetryCount(count: number) {
      this.retryCount = count
    },
    refreshToken(newToken: string) {
      this.tokenId = newToken
    },
    refreshAuthToken(newToken: string) {
      this.authorization = newToken
    },
    hasAllPermission(permissions: string[]) {
      return permissions.every(permission => {
        return Boolean(this.userPermissions[permission])
      })
    },
  },
})
