import { ITipsConfiguration } from '@/types'
import config from '@/config'
import { requests } from '.'

interface DefaultInvoice {
  altId: string
  altType: string
}

interface InvoiceFilters extends DefaultInvoice {
  status?: string
  startAt?: string
  endAt?: string
  search?: string
  paymentMode?: string
  contactId?: string
}

interface GetInvoices extends InvoiceFilters {
  limit: number
  offset: number
}
interface GetInvoice extends DefaultInvoice {
  invoiceId: string
}

type GetInvoiceSettings = DefaultInvoice

export interface SetInvoiceSettings extends DefaultInvoice {
  termsNote?: string
  title?: string
  customNotification?: object
  businessDetails?: object
  dueAfterXDays?: number
  minimumPercentagePartialPayment?: number
  tipsConfiguration?: ITipsConfiguration
}

export enum InvoiceUpdateAction {
  DELETE = 'delete',
  VOID = 'void',
}

export enum UrlType {
  INVOICE = 'invoice',
  RECEIPTS = 'receipts',
}

export interface GetUrlParams {
  id: string
  type: UrlType
  shortUrl: boolean
}

export const InvoiceService = {
  getInvoiceSettings: (params: GetInvoiceSettings) =>
    requests(config.invoicesServiceUrl).get('/invoices/settings', {
      params,
    }),

  updateInvoiceSettings: (params: SetInvoiceSettings) =>
    requests(config.invoicesServiceUrl).post('/invoices/settings', params),

  getInvoices: (params: GetInvoices | GetInvoice) =>
    requests(config.invoicesServiceUrl).get('/invoices/', {
      params,
    }),

  generateUrl: (params: GetUrlParams) =>
    requests(config.invoicesServiceUrl).get(`/invoices/gen-url`, { params }),

  cloneDefaultInvoiceTemplate: (params: any) => {
    return requests(config.invoicesServiceUrl).post(
      '/invoices/settings/clone-template',
      params
    )
  },
}
