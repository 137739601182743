import '@gohighlevel/ghl-canvas/dist/ghl-canvas.css'
import '@gohighlevel/ghl-ui/dist/style.css'
import './assets/index.css'

import App from './App.vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import i18n from './locales'
import mitt from 'mitt'
import router from './router'
import { setupSentry } from './util/setupSentry'

const app = createApp(App)
declare global {
  interface Window {
    Square: any
    CollectJS: any
  }
}
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(VueQueryPlugin)

setupSentry(app, router, process.env.NODE_ENV)
const emitter = mitt()
app.config.globalProperties.emitter = emitter
app.mount('#app')
