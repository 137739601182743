import {
  DiscountType,
  EmailStatus,
  PaymentStatus,
  RecoveryStatus,
  TaxCalculation,
  TransactionStatus,
} from '@/util/const'

import { SenderConfiguration } from '@/components/invoices/invoice.settings.type'

export interface Address {
  addressLine1?: string | undefined
  addressLine2?: string | undefined
  city?: string | undefined
  state?: string | undefined
  countryCode?: string | undefined
  postalCode?: string | undefined
}

export interface BusinessDetails {
  name: string
  logoUrl?: string | undefined
  phoneNo?: string | undefined
  address?: Address
  website?: string | undefined
  customValues?: object[] | undefined
}

export interface CreateSubscription {
  liveMode: boolean
  price?: any
  paymentMethodId?: string
  providerType?: string
  qty?: number
  amount?: number
  taxes?: any[]
  couponCode?: string
  amountSummary?: any
  loadingAmountSummary?: boolean
  startDate?: string
  contactId?: string
  contactDetails?: any
}
export interface ContactCustomField {
  field: object
  value?: any
}

export enum SubscriptionSource {
  CONTACT_VIEW = 'contact_view',
  SUBSCRIPTION_VIEW = 'subscription_view',
}

export const DEFAULT_REMINDER_SUBJECT =
  '[{{ invoice.company.name }}] Reminder : Invoice Due on {{ invoice.due_date }}'

export const DEFAULT_REMINDER_INVOICE_CONDITION = 'invoice_overdue'
export const DEFAULT_INTERVAL_TYPE = 'daily'
export const DEFAULT_INTERVAL_TIME = 'after'
export const DEFAULT_MAX_REMINDER = 5
export const DEFAULT_REMINDER_NUMBER = 3
export const DEFAULT_TEMPLATE_ID_VALUE = 'default'
export const DEFAULT_REMINDER_NAME = 'Reminder 1'

export const ReminderTimeOptions = [
  {
    label: 'After',
    value: 'after',
  },
  {
    label: 'Before',
    value: 'before',
  },
]

export const ReminderInvoiceConditionOptions = [
  {
    label: 'Invoice is sent',
    value: 'invoice_sent',
  },
  {
    label: 'Invoice is overdue',
    value: 'invoice_overdue',
  },
]

export interface ContactSearchResponse {
  contacts: {
    id: string
    contactName: string
    email: string
    additionalEmails: {
      email: string
    }[]
    profilePhoto: string
  }[]
  total: number
}

export interface SentTo {
  email?: string[]
  emailCc?: string[]
  emailBcc?: string[]
  phoneNo?: string[]
}

export enum XeroIntegrationStatus {
  INITIATED = 'initiated',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
}

export interface Discount {
  value: number
  type: DiscountType
  validOnProductIds?: string[]
}

export enum PaymentScheduleStatus {
  PENDING = 'pending',
  PAID = 'paid',
}

export enum InvoiceNotificationSubtype {
  FIRST_TIME = 'customerSendInvoice',
  PAYMENT_SCHEDULE_SENT = 'customerSendPaymentSchedule',
  PAYMENT_SCHEDULE_SUCCEEDED__PAYEE = 'teamPaymentScheduleSuccess',
  PAYMENT_SCHEDULE_SUCCEEDED__PAYER = 'customerPaymentScheduleSuccess',
  PAYMENT_SUCCEEDED__PAYEE = 'teamPaymentSuccess',
  PAYMENT_SUCCEEDED__PAYER = 'customerPaymentSuccess',
  AUTO_PAYMENT_SUCCEEDED__PAYEE = 'teamAutoPaymentSuccess',
  AUTO_PAYMENT_SUCCEEDED__PAYER = 'customerAutoPaymentSuccess',
  PAYMENT_FAILED__PAYEE = 'teamPaymentFailure',
  PAYMENT_FAILED__PAYER = 'customerPaymentFailure',
  AUTO_PAYMENT_FAILED__PAYEE = 'teamAutoPaymentFailure',
  AUTO_PAYMENT_FAILED__PAYER = 'customerAutoPaymentFailure',
  AUTO_PAYMENT_INFO__PAYER = 'customerAutoPaymentInfo',
  AUTO_PAYMENT_AMOUNT_CHANGED__PAYER = 'customerAutoPaymentAmountChanged',
  AUTO_PAYMENT_SKIP__PAYEE = 'teamAutoPaymentSkip',
  RECURRING_SEND_INVOICE_FAILED__PAYEE = 'teamRecurringSendInvoiceFailed',
  INVOICE_REMINDER = 'invoiceReminder',
}

export enum DefaultTemplateName {
  AUTO_PAYMENT_INFO__PAYER = 'Auto payment information',
  FIRST_TIME = 'Invoice received',
}

export interface Tax {
  _id: string
  altId: string
  altType: string
  name: string
  rate: string
  calculation: TaxCalculation
  description?: string
  taxId?: string
  isArchived: boolean
  createdAt: string
  isAutomaticTax?: boolean // flag to check it it is calculated automatic tax calculated at server. Setting it in client
}

export interface ITipsConfiguration {
  tipsPercentage: number[]
  tipsEnabled: boolean
}

export interface ITipsReceived {
  tipAmount: number
  tipPercentage: number
}

export enum TemplatesTabsList {
  INVOICE_TEMPLATES = 'invoice_templates',
  ESTIMATE_TEMPLATES = 'estimate_templates',
}

export interface InvoiceProductSetting {
  enableImportProductDescription?: boolean
}
export enum LateFeesType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}
export enum LateFeesInterval {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  ONE_TIME = 'one_time',
}
export enum LateFeesGraceInterval {
  DAY = 'day',
}
export interface LateFeesFrequency {
  interval: LateFeesInterval
  intervalCount: number
}
export interface LateFeesGracePeriod {
  interval: LateFeesGraceInterval
  intervalCount: number
}
export interface LateFeesCharges {
  name: string
  description: string
  chargedOnAmount: number
  total: number
  entityType: string
  entityId: string
  meta?: any
}
export enum LateFeesMaxFeesType {
  FIXED = 'fixed',
}
export interface LateFeesMaxFees {
  type: LateFeesMaxFeesType
  value: number
}
export interface LateFeesConfiguration {
  enable: boolean
  type: LateFeesType
  grace: LateFeesGracePeriod
  value: number
  frequency: LateFeesFrequency
  charges?: LateFeesCharges[]
  maxLateFees?: LateFeesMaxFees
  collectedLateFees: number
  totalLateFees: number
  meta?: any
}

export interface InvoiceSetting {
  altId: string
  altType: string
  termsNote: string
  title: string
  deleted?: string
  customNotification?: any
  businessDetails?: BusinessDetails
  dueAfterXDays?: number
  minimumPercentagePartialPayment?: number
  customFields?: string[] | undefined
  senderConfiguration?: SenderConfiguration
  productSettings?: InvoiceProductSetting
  lateFeesConfiguration?: LateFeesConfiguration
  tipsConfiguration?: ITipsConfiguration
  invoiceNumberPrefix?: string
}

export interface FormattedOrder {
  _id?: string
  altId: string
  altType: string
  contactId?: string
  contactName?: string
  contactEmail?: string
  currency: string
  amount: number
  subtotal: number
  discount: number
  couponCode?: string
  status: string
  liveMode: boolean
  totalProducts: number
  onetimeProducts?: number
  recurringProducts?: number
  sourceType: string
  sourceName?: string
  sourceId?: string
  sourceMeta?: any
  createdAt: string
  updatedAt: string
}

export interface Order {
  _id?: string
  altId: string
  altType: string
  contactId: string
  contactSnapshot: any // @todo
  currency: string
  amount: number
  status: string
  liveMode: boolean
  source: any // @todo
  items: Array<any> // @todo
  coupon?: any
  trackingId: string
  fingerprint: string
  createdAt: string
  updatedAt: string
  fulfillmentStatus: string
  amountSummary: any
  shippingRate: any
  taxSummary: any
  automaticTaxesCalculated: boolean
  paymentStatus: PaymentStatus
  paymentMethod: Record<string, any>
  taxInclusive?: boolean
  notes?: Array<any>
}

export interface FormattedSubscription {
  _id?: string
  altId: string
  altType: string
  contactId?: string
  contactName?: string
  contactEmail?: string
  currency: string
  amount: number
  status: string
  liveMode: boolean
  entityType: string
  entityId: string
  entitySourceType: string
  entitySourceName: string
  entitySourceId?: string
  entitySourceMeta?: any
  subscriptionId?: string
  paymentProviderType: string
  customProvider?: CustomProviderType
  paymentProviderConnectedAccount?: string
  ipAddress?: string
  createdAt: string
  updatedAt: string
}

export interface CustomProviderType {
  name?: string
  title?: string
}

export interface Subscription {
  _id?: string
  altId: string
  altType: string
  contactId: string
  contactSnapshot: any // @todo
  currency: string
  amount: number
  status: string
  liveMode: boolean
  entityType: string
  entityId: string
  entitySource: any // @todo
  subscriptionId?: string
  subscriptionSnapshot?: any // Available for stripe subscription
  recurringProduct?: any // Available for Authorize.net and NMI
  coupon?: any // Available after subscription coupons are deployed
  paymentProvider: any // @todo
  meta: any
  ipAddress: string
  createdAt: string
  updatedAt: string
}

export enum TransactionEntitySourceType {
  INVOICE = 'invoice',
}

export interface FormattedTransaction {
  _id?: string
  altId: string
  altType: string
  contactId?: string
  contactName?: string
  contactEmail?: string
  currency: string
  amount: number
  status: TransactionStatus
  liveMode: boolean
  entityType: string
  entityId: string
  entitySourceType: string
  entitySourceName: string
  entitySourceId?: string
  entitySourceMeta?: any
  subscriptionId?: string
  chargeId: string
  chargeSnapshot: any
  paymentProviderType: string
  paymentProviderConnectedAccount?: string
  ipAddress?: string
  createdAt: string
  updatedAt: string
  amountRefunded?: number
  paymentMethod?: any
}

export interface Transaction {
  _id?: string
  altId: string
  altType: string
  contactId: string
  contactSnapshot: any // @todo
  currency: string
  amount: number
  status: TransactionStatus
  liveMode: boolean
  entityType: string
  entityId: string
  entitySource: any // @todo
  chargeId: string
  chargeSnapshot: any // @todo
  subscriptionId?: string
  paymentProvider: any // @todo
  meta: any
  ipAddress: string
  createdAt: string
  updatedAt: string
  receiptId?: string
}

export interface StripeConnectionKey {
  _id: string
  accountId: string
  publishableKey: string
  liveMode: boolean
}

export interface AuthorizeNetConnectionKey {
  _id: string
  accountId: string
  publishableKey: string
  liveMode: boolean
  merchantPublicClientKey?: string
  merchantLoginId?: string
}

export interface SquareConnectionKey {
  _id: string
  merchantGatewayId: string
  clientId: string
  locationId: string
  liveMode: boolean
}

export interface NMIConnectionKey {
  _id: string
  merchantGatewayId: string
  merchantPublicKey: string
  liveMode: boolean
  version: number
  merchantSecurityKey: string
}

export interface StripeKeys {
  test: StripeConnectionKey
  live: StripeConnectionKey
}

export interface AuthorizeNetKeys {
  test: AuthorizeNetConnectionKey
  live: AuthorizeNetConnectionKey
}

export interface SquareKeys {
  test: SquareConnectionKey
  live: SquareConnectionKey
}

export interface NMIKeys {
  test: NMIConnectionKey
  live: NMIConnectionKey
}

export enum PaymentProviderType {
  STRIPE = 'stripe',
  PAYPAL = 'paypal',
  MANUAL = 'manual',
  AUTHORIZE_NET = 'authorize-net',
  NMI = 'nmi',
  WHITE_LABEL = 'white-label',
  CUSTOM_PROVIDER = 'custom-provider',
  SQUARE = 'square',
}

export enum UpdateOperationType {
  UNSET = 'unset',
}

export enum ChargeCardType {
  NEW_CARD = 'newCard',
  SAVED_CARDS = 'savedCards',
}

export type CustomData = {
  label: string
  value?: string
  children?: CustomData[]
  meta?: {
    [key: string]: any
  }
}

export interface ICustomValue {
  id: string
  name: string
  fieldKey: string
  value: string
  children?: ICustomValue[]
}

export interface ICustomField {
  id: string
  name: string
  fieldKey: string
  dataType: string
  picklistOptions: string[]
}

export interface FulfillmentTracking {
  trackingNumber?: string
  shippingCarrier: string
  trackingUrl?: string
}

export interface Fulfillment {
  _id?: string
  altId: string
  altType: string
  items?: Array<any>
  trackings?: Array<FulfillmentTracking>
  notifyCustomer?: boolean
  createdAt: string
  updatedAt: string
}

export type GenericEvent<T> = Event & {
  target: T
}

export enum SourceType {
  FUNNEL = 'funnel',
  WEBSITE = 'website',
  INVOICE = 'invoice',
  CALENDAR = 'calendar',
  TEXT2PAY = 'text2Pay',
  MEMBERSHIP = 'membership',
  MOBILE_APP = 'mobile_app',
  COMMUNITIES = 'communities',
  POINT_OF_SALE = 'point_of_sale',
  MANUAL = 'manual',
  FORM = 'form',
  SURVEY = 'survey',
  PAYMENT_LINK = 'payment_link',
  EXTERNAL = 'external',
}

export enum SourceSubType {
  WOOCOMMERCE = 'woocommerce',
}

interface CartItem {
  title: string
  price: string | number
  line_price: string | number
  quantity: number
  image: string
}
export interface UserDetails {
  fullName?: string
  phoneNumber?: string
  contactId?: string
  address?: string
  city?: string
  state?: string
  zipcode?: string
  country?: string
}

export interface AbandonedCartNotification {
  locationId: string
  fingerprint: string
  email: string
  orderId: string
  liveMode: boolean
  deleted: boolean
  pageId: string
  funnelId: string
  funnelName?: string
  items: Array<CartItem>
  emailStatus: EmailStatus
  recoveryStatus: RecoveryStatus
  cloudTaskName: string
  pageUrl: string
  productListPageUrl: string
  contactCountry: string
  userDetails?: UserDetails
  currency?: string
  total?: number
  couponCode?: string
  discount?: number
  createdAt: string
  updatedAt: string
}
export enum TemplateType {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum DefaultSubject {
  CUSTOMER_SEND_INVOICE = '[{{ invoice.company.name }}] Invoice received',
  CUSTOMER_SEND_ESTIMATE = '[{{ estimate.company.name }}] Estimate received',
  CUSTOMER_PAYMENT_SUCCESS = '[{{ invoice.company.name }}] Invoice payment successful',
  CUSTOMER_AUTO_PAYMENT_SUCCESS = '[{{ invoice.company.name }}] Invoice payment successful',
  TEAM_PAYMENT_SUCCESS = '[{{ invoice.customer.name }}] Invoice payment successful',
  TEAM_AUTO_PAYMENT_SUCCESS = '[{{ invoice.customer.name }}] Invoice payment successful',
  TEAM_PAYMENT_FAILURE = '[{{ invoice.customer.name }}] Invoice payment failed',
  CUSTOMER_PAYMENT_FAILURE = '[{{ invoice.company.name }}] Invoice payment failed',
  TEAM_AUTO_PAYMENT_FAILURE = '[{{ invoice.customer.name }}] Invoice auto payment failed',
  CUSTOMER_AUTO_PAYMENT_FAILURE = '[{{ invoice.company.name }}] Invoice auto payment error',
  CUSTOMER_AUTO_PAYMENT_INFO = '[{{ invoice.company.name }}] Invoice auto payment information',
  CUSTOMER_AUTO_PAYMENT_AMOUNT_CHANGED = '[{{ invoice.company.name }}] Invoice auto payment amount changed',
  TEAM_AUTO_PAYMENT_SKIP = '[{{ invoice.customer.name }}] Invoice auto payment skipped',
  TEAM_RECURRING_SEND_INVOICE_FAILED = '[{{ invoice.customer.name }}]  Invoice not sent',
  TEAM_ESTIMATE_ACCEPTED = '[{{ estimate.company.name }}] Estimate accepted',
  TEAM_ESTIMATE_DECLINED = '[{{ estimate.company.name }}] Estimate declined',
}

export enum EstimatesSubNotificationsType {
  CUSTOMER_SEND_ESTIMATE = 'customerSendEstimate',
  TEAM_ESTIMATE_ACCEPTED = 'teamEstimateAccepted',
  TEAM_ESTIMATE_DECLINED = 'teamEstimateDeclined',
}

export class PaymentMethods {
  'stripe': {
    enableBankDebitOnly: boolean
  }
}

export enum Permissions {
  EXPORT_PAYMENTS_ENABLED = 'export_payments_enabled',
}

export interface State {
  name: string
  code: string
}

export interface Country {
  name: string
  code: string
  states: State[]
}
export enum EmitterKeys {
  REFRESH_INVOICE_SETTINGS = 'refresh-invoice-settings',
  EDIT_EMAIL_TEMPLATE_CLOSED = 'edit-email-template-closed-payments',
  UPDATE_INVOICE_SETTINGS = 'update-invoice-settings',
  EDIT_EMAIL_TEMPLATE_PAYMENTS = 'edit-email-template-payments',
  UPDATE_DEFAULT_REMINDER = 'update-default-reminder',
  OPEN_GENERIC_MODAL = 'open-generic-modal',
  CLOSE_GENERIC_MODAL = 'close-generic-modal',
  DELETE_REMINDER = 'delete-reminder',
  REFRESH_REMINDER_SETTINGS = 'refresh-reminder-settings',
  DISCONNECT_INTEGRATION_MODAL = 'open-disconnect-integration',
  OPEN_INVOICE_TEMPLATE_PREVIEW = 'open-invoice-template-preview',
  SAVE_INVOICE = 'save-invoice',
  PAUSE_SUBSCRIPTION_MODAL = 'pause-subscription-modal',
  RESUME_SUBSCRIPTION_MODAL = 'resume-subscription-modal',
  SET_CONTACT_DETAILS = 'set-contact-details',
  SELECTED_MEDIA_FILE = 'selected-media-file',
  OPEN_MANAGE_INVOICE_PAYMENT_METHODS = 'open-manage-invoice-payment-methods',
  UPDATE_INVOICE_PAYMENT_METHODS = 'update-invoice-payment-methods',
}

export enum EventBusEvents {
  SEND_ZERO_AMOUNT_INVOICE = 'open-send-zero-amount-invoice',
  SEND_INVOICE = 'open-send-invoice',
  SEND_SCHEDULE = 'open-schedule-invoice',
  OPEN_LATE_FEES_MODAL = 'open-late-fees-modal',
}

export enum TaxCalculationMethod {
  INCLUSIVE = 'inclusive',
  EXCLUSIVE = 'exclusive',
}

export enum PauseDuration {
  INDEFINITE = 'indefinite',
  DEFINITE = 'definite',
}

export enum InvoiceBehaviour {
  DRAFT = 'draft',
  VOID = 'void',
}
