import { TemplateType } from '@/types'
import config from '@/config'
import { requests } from '.'

export const DEFAULT_TEMPLATE_FETCH_LIMIT = 1000

export const LocationService = {
  getLocationById: (locationId: string) =>
    requests(config.locationServiceUrl).get(`/locations/${locationId}`),
  getCustomValues: (locationId: string) =>
    requests(config.locationServiceUrl).get(
      `/locations/${locationId}/customValues`
    ),
  getCustomFields: (locationId: string) =>
    requests(config.locationServiceUrl).get(
      `/locations/${locationId}/customFields`
    ),
  getTemplatesForLocationAndType: (
    locationId: string,
    templateType: TemplateType
  ) =>
    requests(config.locationServiceUrl).get(
      `locations/${locationId}/templates?type=${templateType}&limit=${DEFAULT_TEMPLATE_FETCH_LIMIT}`,
      {
        headers: {
          'internal-channel': 'payments',
          'location-id': locationId,
        },
      }
    ),
}
