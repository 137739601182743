import config from '@/config'
import { requests } from '.'

export enum Modules {
  Conversations = 'conversations',
  Emails = 'emails',
  SocialMedia = 'social-media',
  Invoices = 'invoices',
  Estimates = 'estimates',
}

export interface CustomValue {
  label: string
  value: string
  children?: CustomValue[]
  meta: {
    id: string
    locationId: string
    value: string
  }
}

export default class CustomDataService {
  get endpoint() {
    // return 'http://localhost:5076/custom-data'
    // return 'https://staging.backend.leadconnectorhq.com/custom-data'
    return `${config.restApiUrl}/custom-data`
  }

  async getCustomValuesAndData(locationId: string, module: Modules) {
    try {
      const { data } = await requests(this.endpoint).get(
        `/${module}?types=custom-values&locationId=${locationId}`
      )

      const customValues = mapCustomValues(data['custom-values'])

      return {
        customValues,
        static: data?.static,
      }
    } catch (error) {
      console.error('Error while getting custom values: ' + error)
    }
  }
}

function mapCustomValues(customValues: CustomValue[]): any[] {
  return customValues
    .filter(
      (customValue: CustomValue) =>
        !customValue.children || customValue.children.length !== 0
    )
    .map((customValue: CustomValue) => {
      if (customValue.children) {
        return {
          name: customValue?.label,
          children: mapCustomValues(customValue.children),
        }
      } else {
        return {
          name: customValue?.label,
          fieldKey: customValue?.value?.replaceAll(' ', ''),
          id: customValue?.meta?.id,
          value: customValue?.meta.value,
        }
      }
    })
}
