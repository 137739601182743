import { createRouter, createWebHistory } from 'vue-router'

import { useMainStore } from '@/store/main'

const routes = [
  // {
  //   path: '/location/:locationId/payments/invoices',
  //   name: 'list',
  //   component: () => import('../pages/invoice/ListPage.vue'),
  // },
  // {
  //   path: '/location/:locationId/payments/integrations',
  //   name: 'payments-app',
  //   children: [
  //     {
  //       path: '',
  //       name: 'payment-integrations-v2',
  //       component: () =>
  //         import('../pages/payment-integrations/PaymentIntegrations.vue'),
  //     },
  //     {
  //       path: ':route/manage',
  //       name: 'manage-payment-integrations',
  //       component: () =>
  //         import('../pages/payment-integrations/ManagePaymentIntegrations.vue'),
  //     },
  //     {
  //       path: 'all',
  //       name: 'all-payment-integrations',
  //       component: () =>
  //         import('../pages/payment-integrations/AllPaymentIntegrations.vue'),
  //     },
  //   ],
  // },
  {
    path: '/location/:locationId/payments/integrations',
    name: 'payment-integrations-v2',
    component: () =>
      import('../pages/payment-integrations/PaymentIntegrations.vue'),
  },
  {
    path: '/location/:locationId/payments/integrations/:route/manage',
    name: 'manage-payment-integrations',
    component: () =>
      import('../pages/payment-integrations/ManagePaymentIntegrations.vue'),
  },
  {
    path: '/location/:locationId/payments/integrations/all',
    name: 'all-payment-integrations',
    component: () =>
      import('../pages/payment-integrations/AllPaymentIntegrations.vue'),
  },
  {
    path: '/location/:locationId/payments/databridge/xero',
    name: 'xero',
    component: () => import('../components/data-bridge/index.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/orders',
    name: 'orders',
    component: () => import('../pages/orders/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/orders/:orderId',
    name: 'order',
    component: () => import('../pages/orders/DetailsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/abandoned-checkouts',
    name: 'abandoned-checkouts',
    component: () => import('../pages/abandoned-checkouts/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/abandoned-checkouts/:notificationId',
    name: 'abandoned-checkouts-details',
    component: () => import('../pages/abandoned-checkouts/DetailsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/subscriptions',
    name: 'subscriptions',
    component: () => import('../pages/subscriptions/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/subscriptions/:id',
    name: 'subscription',
    component: () => import('../pages/subscriptions/DetailsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/transactions',
    name: 'transactions',
    component: () => import('../pages/transactions/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/transactions/:txnId',
    name: 'transaction',
    component: () => import('../pages/transactions/DetailsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/v2/taxes',
    name: 'taxes',
    component: () => import('../pages/taxes/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/coupons',
    name: 'coupons',
    component: () => import('../pages/coupons/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/coupons/create',
    name: 'create-coupon',
    component: () => import('../pages/coupons/CreatePage.vue'),
  },
  {
    path: '/location/:locationId/payments/coupons/edit/:id',
    name: 'edit-coupon',
    component: () => import('../pages/coupons/CreatePage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/collections',
    name: 'collections',
    component: () => import('../pages/collections/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/inventory',
    name: 'inventory',
    component: () => import('../pages/inventory/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/reviews',
    name: 'product-reviews',
    component: () => import('../pages/reviews/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products',
    name: 'products',
    component: () => import('../pages/products/ListPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/create',
    name: 'create-product',
    component: () => import('../pages/products/CreatePage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/edit/:id',
    name: 'edit-product',
    component: () => import('../pages/products/CreatePage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/view/:id',
    name: 'view-product',
    component: () => import('../pages/products/ViewPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/:productId/variants/:priceId',
    name: 'view-product-variants',
    component: () => import('../pages/products/VariantsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/inventory/:productId/:priceId',
    name: 'product-inventory',
    component: () => import('../pages/products/InventoryHistoryPage.vue'),
  },
  {
    path: '/location/:locationId/payments/products/stripe/import',
    name: 'import-stripe-product-v2',
    component: () => import('../pages/products/ImportStripePage.vue'),
  },
  {
    path: '/location/:locationId/payments/invoice/settings',
    name: 'invoice-settings',
    component: () => import('../pages/invoice/InvoiceSettingsPage.vue'),
  },
  {
    path: '/location/:locationId/payments/settings',
    name: 'payment-settings',
    component: () => import('../pages/settings/PaymentSettingsPage.vue'),
    children: [
      {
        path: 'receipts',
        name: 'payment-settings-receipts',
        component: () => import('../pages/settings/ReceiptSettingsPage.vue'),
      },
      {
        path: 'taxes',
        name: 'payment-settings-taxes',
        component: () => import('../pages/taxes/ListPage.vue'),
      },
    ],
  },
  {
    path: '/location/:locationId/payments/invoice-templates',
    name: 'invoice-template-list',
    component: () => import('../pages/invoice-template/ListPage.vue'),
  },
]

const parentRoutes = [
  {
    path: '/location/:locationId/contacts/detail/:contactId',
    name: 'contact_detail-v2',
  },
  {
    path: '/location/:locationId/funnels-websites/funnels/:funnelId',
    name: 'funnels-detail-v2',
  },
  {
    path: '/location/:locationId/funnels-websites/funnels/:funnelId/steps/:stepId/overview',
    name: 'funnels-step-detail-v2',
  },
  {
    path: '/location/:locationId/funnels-websites/websites/:websiteId/pages',
    name: 'websites-detail-v2',
  },
  {
    path: '/location/:locationId/payments/integrations',
    name: 'payment-integrations',
  },
  {
    path: '/location/:locationId/settings/calendars',
    name: 'settings-calendars-list',
  },
  {
    path: '/location/:locationId/memberships/courses/offers/:offerId/edit',
    name: 'membership-offer-detail',
  },
  {
    path: '/location/:locationId/contacts/detail/:contactId?view=payments',
    name: 'contact-payments-tab',
  },
  {
    path: '/location/:locationId/form-builder-v2/:formId',
    name: 'funnel-form-builder',
  },
  {
    path: '/location/:locationId/survey-builder-v2/:surveyId',
    name: 'funnel-survey-builder',
  },
  {
    path: '/location/:locationId/payments/v2/paymentlinks/edit/:id',
    name: 'payment-links-editor',
  },
  {
    path: '/location/:locationId/integration/:id',
    name: 'integration-details-page',
  },
  {
    path: '/location/:locationId/settings/integrations/list',
    name: 'settings-integration-list-page',
  },
  {
    path: '/location/:locationId/payments/invoices',
    name: 'payment-products-list-invoices',
  },
  {
    path: '/location/:locationId/payments/recurring-templates',
    name: 'payment-products-list-recurring-invoices',
  },
  {
    path: '/location/:locationId/payments/invoices/:invoiceId',
    name: 'payment-products-invoice-editor',
  },
  {
    path: '/location/:locationId/payments/recurring-templates/:scheduleId',
    name: 'payment-products-recurring-invoice-editor',
  },
  {
    path: '/location/:locationId/payments/invoice-templates/:templateId',
    name: 'payment-products-invoice-template-editor',
  },
  {
    path: '/location/:locationId/payments/v2/estimates/templates/edit/:id',
    name: 'estimates-template-editor',
  },
  {
    path: '/location/:locationId/payments/v2/estimates/edit/:id',
    name: 'estimates-editor',
  },
  {
    path: '/location/:locationId/payments/invoices/:invoiceId',
    name: 'invoice_edit',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(to => {
  emitRouteChangeToParent(to.fullPath)
})

interface Options {
  newTab?: boolean
  forceReload?: boolean
}

export function emitRouteChangeToParent(path: string, options?: Options) {
  if (!path) return
  const store = useMainStore()
  try {
    if (store.appHandshake) {
      store.appHandshake.then((parent: any) => {
        parent.emit('spm-ts', {
          router: path,
          ...options,
        })
      })
    }
  } catch (error) {
    console.error(error)
  }
}

interface ParentRoute {
  name: string
  params: any
  query?: any
}

export function redirectOnParentRoute(route: ParentRoute, options?: Options) {
  try {
    const routePath = [...parentRoutes, ...routes].find(
      r => r.name === route.name
    )
    let routeFullPath = routePath?.path
    if (route.params) {
      Object.keys(route.params).forEach(key => {
        routeFullPath = routeFullPath?.replace(`:${key}`, route.params[key])
      })
    }
    if (route.query) {
      const queryParams = Object.entries(route.query)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
      routeFullPath += '?' + queryParams
    }
    if (!routeFullPath) throw new Error('Route path not found')
    emitRouteChangeToParent(routeFullPath, options)
  } catch (error) {
    console.error(error)
  }
}

export default router
