interface Config {
  mode: string
  restApiUrl: string
  locationServiceUrl: string
  appEngineUrl: string
  newAppEngineUrl: string
  productsServiceUrl: string
  mediaCenterUrl: string
  contactServiceUrl: string
  paymentsServiceUrl: string
  ecommerceServiceUrl: string
  invoicesServiceUrl: string
  invoicePreviewUrl: string
  membershipUrl: string
  emailServiceUrl: string
  publicApiUrl: string
  userApiUrl: string
  enterpriseCaptchaSiteKey: string
  dataBridgeUrl: string
  xeroClientId: string
  saasApiUrl: string
}

const config: { [key: string]: Config } = {
  development: {
    mode: 'dev',
    restApiUrl: 'http://localhost:5075',
    locationServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    appEngineUrl: 'https://highlevel-staging.appspot.com',
    newAppEngineUrl: 'http://localhost:5000',
    productsServiceUrl: 'https://staging.backend.leadconnectorhq.com', //'http://localhost:5053',
    mediaCenterUrl: 'https://hl-media-center.web.app',
    contactServiceUrl: 'http://localhost:5058',
    paymentsServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    ecommerceServiceUrl: 'http://localhost:6056',
    invoicesServiceUrl: 'http://localhost:5075',
    invoicePreviewUrl: 'http://localhost:3041',
    membershipUrl: 'https://staging.backend.leadconnectorhq.com/membership',
    emailServiceUrl: 'https://staging.backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://public-api-dot-highlevel-staging.uc.r.appspot.com',
    userApiUrl: 'https://staging.backend.leadconnectorhq.com/users',
    enterpriseCaptchaSiteKey: '6LcPRcoeAAAAAFp3QQjiYeXm9emhikb7IC6QaXz2',
    dataBridgeUrl: 'http://localhost:5755/data-bridge',
    xeroClientId: 'BFAB5C6772B34CF1B8B7FCC5DCE1262F',
    saasApiUrl: 'https://staging.backend.leadconnectorhq.com',
  },
  staging: {
    mode: 'staging',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    locationServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    appEngineUrl: 'https://highlevel-staging.appspot.com',
    newAppEngineUrl: 'https://staging.backend.leadconnectorhq.com/appengine',
    productsServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    mediaCenterUrl: 'https://hl-media-center.web.app',
    contactServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    paymentsServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    ecommerceServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    invoicesServiceUrl: 'https://staging.backend.leadconnectorhq.com',
    invoicePreviewUrl: 'https://link.staging.fastpaydirect.com',
    membershipUrl: 'https://staging.backend.leadconnectorhq.com/membership',
    emailServiceUrl: 'https://staging.backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://public-api-dot-highlevel-staging.uc.r.appspot.com',
    userApiUrl: 'https://staging.backend.leadconnectorhq.com/users',
    enterpriseCaptchaSiteKey: '6LcPRcoeAAAAAFp3QQjiYeXm9emhikb7IC6QaXz2',
    dataBridgeUrl: 'https://staging.backend.leadconnectorhq.com/data-bridge',
    xeroClientId: 'BFAB5C6772B34CF1B8B7FCC5DCE1262F',
    saasApiUrl: 'https://staging.backend.leadconnectorhq.com',
  },
  beta: {
    mode: 'beta',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    locationServiceUrl: 'https://backend.leadconnectorhq.com',
    appEngineUrl: 'https://highlevel-backend.appspot.com',
    newAppEngineUrl: 'https://backend.leadconnectorhq.com/appengine',
    productsServiceUrl: 'https://backend.leadconnectorhq.com',
    mediaCenterUrl: 'https://media-center-prod.web.app',
    contactServiceUrl: 'https://backend.leadconnectorhq.com',
    paymentsServiceUrl: 'https://backend.leadconnectorhq.com',
    ecommerceServiceUrl: 'https://backend.leadconnectorhq.com',
    invoicesServiceUrl: 'https://backend.leadconnectorhq.com',
    invoicePreviewUrl: 'https://link.fastpaydirect.com',
    membershipUrl: 'https://backend.leadconnectorhq.com/membership',
    emailServiceUrl: 'https://backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://rest.gohighlevel.com',
    userApiUrl: 'https://backend.leadconnectorhq.com/users',
    enterpriseCaptchaSiteKey: '6LeDBFwpAAAAAJe8ux9-imrqZ2ueRsEtdiWoDDpX',
    dataBridgeUrl: 'https://backend.leadconnectorhq.com/data-bridge',
    xeroClientId: '6656E6ADB15E4B96A1A0E112552AD8FC',
    saasApiUrl: 'https://services.leadconnectorhq.com',
  },
  production: {
    mode: 'production',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    locationServiceUrl: 'https://backend.leadconnectorhq.com',
    appEngineUrl: 'https://highlevel-backend.appspot.com',
    newAppEngineUrl: 'https://backend.leadconnectorhq.com/appengine',
    productsServiceUrl: 'https://backend.leadconnectorhq.com',
    mediaCenterUrl: 'https://media-center-prod.web.app',
    contactServiceUrl: 'https://backend.leadconnectorhq.com',
    paymentsServiceUrl: 'https://backend.leadconnectorhq.com',
    ecommerceServiceUrl: 'https://backend.leadconnectorhq.com',
    invoicesServiceUrl: 'https://backend.leadconnectorhq.com',
    invoicePreviewUrl: 'https://link.fastpaydirect.com',
    membershipUrl: 'https://backend.leadconnectorhq.com/membership',
    emailServiceUrl: 'https://backend.leadconnectorhq.com/emails',
    publicApiUrl: 'https://rest.gohighlevel.com',
    userApiUrl: 'https://backend.leadconnectorhq.com/users',
    enterpriseCaptchaSiteKey: '6LeDBFwpAAAAAJe8ux9-imrqZ2ueRsEtdiWoDDpX',
    dataBridgeUrl: 'https://backend.leadconnectorhq.com/data-bridge',
    xeroClientId: '6656E6ADB15E4B96A1A0E112552AD8FC',
    saasApiUrl: 'https://backend.leadconnectorhq.com',
  },
}

const override = false

const overrideConfig = {}
const envConfig = config[process.env.NODE_ENV]

export default override ? { ...envConfig, ...overrideConfig } : envConfig
