import axios, { InternalAxiosRequestConfig } from 'axios'

import DataBridgeService from './DataBridgeService'
import { EcommerceService } from './EcommerceService'
import { InvoiceService } from './InvoiceService'
import { InvoiceTemplateService } from './InvoiceTemplateService'
import { LocationService } from './LocationService'
import { MembershipService } from './MembershipService'
import { PaymentService } from './PaymentService'
import { ProductService } from './ProductsService'
import { SaasService } from './saasService'
import { TemplateService } from './TemplateService'
import { UploadFileService } from './UploadFileService'
import config from '../config'
import { setupAutoUpdateToken } from '@/util/setupToken'
import { useMainStore } from '../store/main'

const getHeader = () => {
  return {
    channel: 'APP',
    source: 'WEB_USER',
    version: '2021-07-28',
  }
}

export const requests = (host?: string, passAuthToken = false) => {
  const baseUrl = host || config.restApiUrl
  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: getHeader(),
  })

  axiosInstance.interceptors.request.use(
    async (requestConfig: InternalAxiosRequestConfig) => {
      try {
        const store = useMainStore()
        requestConfig.headers['token-id'] = store.tokenId
        if (passAuthToken) {
          requestConfig.headers[
            'authorization'
          ] = `Bearer ${store.authorization}`
        }
      } catch (e) {
        console.error(e)
      }
      return requestConfig
    }
  )
  setupAutoUpdateToken(axiosInstance)
  return axiosInstance
}

export {
  DataBridgeService,
  EcommerceService,
  InvoiceService,
  InvoiceTemplateService,
  LocationService,
  MembershipService,
  PaymentService,
  ProductService,
  SaasService,
  TemplateService,
  UploadFileService,
}
