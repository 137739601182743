import { useMainStore } from '@/store/main'
import { AxiosInstance } from 'axios'

export function setupAutoUpdateToken(axios: AxiosInstance) {
  const wait = (ms: number) => new Promise(res => setTimeout(res, ms))
  axios.interceptors.response.use(
    function (res) {
      // console.info(
      //     `Method=${res.config.method} URL=${res.config.url} Code=${res.status}`
      // )
      return res
    },
    async function axiosRetryInterceptor(err) {
      const store = useMainStore()

      if (err.config && err.response && err.response.status === 401) {
        if (store.appHandshake) {
          // @ts-expect-error
          store.appHandshake.then(parent => {
            parent.emit('refresh-token')
          })
        }
        const retryCount = store.retryCount

        if (retryCount >= 5) {
          store.updateRetryCount(0)
          return Promise.reject(err)
        }
        await wait(2 ** retryCount * 10) // exponential back off time. -> wait for token
        store.updateRetryCount(retryCount + 1)
        return axios.request(err.config)
      }
      return Promise.reject(err)
    }
  )
}
