import {
  AltType,
  CardType,
  DiscountType,
  ManualPaymentMode,
} from '@/util/const'
import {
  FulfillmentTracking,
  InvoiceBehaviour,
  PauseDuration,
  PaymentProviderType,
} from '@/types'

import config from '@/config'
import { requests } from '.'

interface BasicParams {
  altId: string
  altType: string
}
export interface OpaqueData {
  dataValue: string
  dataDescriptor: string
}
interface GetOrders extends BasicParams {
  limit: number
  offset: number
  status?: string
  paymentStatus?: string
  paymentMode?: string
  sourceType?: string
  startAt: string
  endAt: string
  search?: string
}

interface GetOrdersForExport extends BasicParams {
  status?: string
  paymentStatus?: string
  paymentMode?: string
  sourceType?: string
  startAt: string
  endAt: string
  search?: string
}

interface GetOrder extends BasicParams {
  orderId: string
}

export enum CardOnFile {
  ADD_CARD_ON_FILE = 'addCardOnFile',
}

interface GetSubscription extends BasicParams {
  id?: string
  subscriptionId?: string
}

interface ScheduleExportSubscriptions extends BasicParams {
  status?: string
  paymentMode?: string
  entitySourceType?: string
  startAt: string
  endAt: string
  search?: string
  entityId?: string
  contactId?: string
}

interface GetSubscriptions extends ScheduleExportSubscriptions {
  limit: number
  offset: number
}

interface GetTransactions extends BasicParams {
  limit: number
  offset?: number
  status?: string
  paymentMode?: string
  entitySourceType?: string
  startAt?: string
  endAt?: string
  search?: string
  subscriptionId?: string
  entityId?: string
  contactId?: string
}

interface GetTransactionsForExport extends BasicParams {
  status?: string
  paymentMode?: string
  entitySourceType?: string
  startAt: string
  endAt: string
  search?: string
}

interface GetTransaction extends BasicParams {
  txnId: string
}

interface GetCoupons extends BasicParams {
  limit: number
  offset?: number
  status?: string
  search?: string
}

interface GetCoupon extends BasicParams {
  id?: string
  code?: string
}

export interface CreateCoupon extends BasicParams {
  name: string
  code: string
  discountType: DiscountType
  discountValue: number
  startDate: string
  endDate?: string
  usageLimit?: number
  productIds?: string[]
  applyToFuturePayments?: boolean
  limitPerCustomer?: number
}

interface UpdateCoupon extends CreateCoupon {
  id: string
}

interface DeleteCoupon extends BasicParams {
  id: string
}

interface CreateTax extends BasicParams {
  name: string
  rate: number
  description?: string
  taxId?: string
}

interface updateTax extends BasicParams {
  id: string
  deleted: boolean
}

export interface InitiateStripeCustomerCardSetup extends BasicParams {
  contactId?: string
  liveMode: boolean
  currency: string
  isExistingPaymentMethod: boolean
  paymentMethodId: string
  customerId?: string
}

export interface VerifyStripeCustomerCardSetup extends BasicParams {
  liveMode: boolean
  paymentIntentId: string
}

export interface CancelSubscription extends BasicParams {
  id: string
}

export interface UpdateSubscriptionTrialPeriod extends BasicParams {
  id: string
  trialEndDate: string
}

export interface GetCustomerPaymentMethods {
  locationId: string
  contactId: string
  liveMode: boolean
}

export interface SetupAndSaveAuthorizeNetCard extends BasicParams {
  contactId: string
  liveMode: boolean
  currency: string
  cardCode: string
  opaqueData: OpaqueData
}

export interface SetupAndSaveSquareCard extends BasicParams {
  contactId: string
  liveMode: boolean
  currency: string
  paymentToken: string
}

export interface SetupAndSaveNMICard extends BasicParams {
  contactId: string
  liveMode: boolean
  paymentToken: string
}

export interface RefundRequest extends BasicParams {
  txnId: string
  amount: number
  notes?: string
}

export interface GetTxnRefunds extends BasicParams {
  txnId: string
}

interface ReceiptSettings {
  enabled: boolean
  title: string
  prefix: string
  startNumber: number
  notification: object
}

export interface TaxCategory {
  _id: string
  name: string
  taxJarTaxName: string
  description: string
  parentId: string
  taxCodes: object
}

export interface UpdatePaymentSettings extends BasicParams {
  receipts: ReceiptSettings
}

export interface CreateFulfillment extends BasicParams {
  items: Array<any>
  trackings: Array<FulfillmentTracking>
  notifyCustomer: boolean
}

export interface PrintShippingLabels extends BasicParams {
  orderId: string
  items?: Array<fulfillmentItems>
}

export interface fulfillmentItems {
  priceId: string
  qty: number
}

export interface BaseAction {
  locationId: string
  liveMode: boolean
}

export interface AuthorizeNetConnect extends BaseAction {
  merchantLoginKey?: string
  merchantLoginId: string
  merchantTransactionKey: string
  merchantClientKey?: string
  merchantSignatureKey: string
}
export interface NMIConnect extends BaseAction {
  merchantGatewayId: string
  merchantSecurityKey: string
  merchantPublicKey: string
}

interface StripeConnect {
  locationId: string
}
interface StripeDisconnect extends StripeConnect {
  defaultPaymentProvider?: string
}

interface SquareConnect {
  locationId: string
  liveMode: boolean
}
interface SquareDisconnect extends SquareConnect {
  defaultPaymentProvider?: string
}
interface DisconnectProvider extends BaseAction {
  defaultPaymentProvider?: string
}

export interface PaypalConnectOptions {
  locationId: string
  liveMode: boolean
  redirectURL: string
}

export interface GetPaypalAccounts {
  locationId: string
  liveMode: boolean
}

export interface PaypalConnectWithKeyOptions {
  locationId: string
  liveMode: boolean
  merchantClientId: string
  merchantSecretId: string
}

interface GetConnectedProvidersParams extends BasicParams {
  with?: 'moreProvider'
}
interface PaginationParams {
  limit?: number
  offset?: number
}
interface GetAllProvidersParams extends BasicParams, PaginationParams {
  // type: 'third-party' | 'white-label' | 'marketplace'
}
interface GetProviderDetailParams extends BasicParams {
  type: PaymentProviderType
  providerId?: string
}

interface UpdatePaymentProviderParams extends BasicParams {
  providerType: PaymentProviderType
  data: any
}

interface DefaultProviderParams extends BasicParams {
  defaultProvider: PaymentProviderType
  whiteLabelProviderId?: string
}

interface ConnectWhiteLabelParams extends BasicParams {
  live?: NMIConnect | AuthorizeNetConnect
  test?: NMIConnect | AuthorizeNetConnect
}
interface DisconnectWhiteLabelParams extends BasicParams {
  liveMode: boolean
}

export interface PauseSubscriptionParam {
  subscriptionId: string
  altId: string
  altType: string
  pauseDuration: PauseDuration
  pauseEndDate?: Date
  invoiceBehaviour: InvoiceBehaviour
}

export interface SubscriptionIdParam extends BasicParams {
  subscriptionId: string
}

export interface RecordPaymentParams {
  altId: string
  altType: AltType
  mode: ManualPaymentMode
  amount: number
  card?: {
    type?: CardType
    last4?: string
  }
  cheque?: {
    number?: string
  }
  notes?: string
}

export interface CreateSubscriptionSchedule extends BasicParams {
  orderId: string
  startDate: string
  paymentMethodId: string
  providerType: string
  liveMode: boolean
}

export enum OrderSourceType {
  MANUAL = 'manual',
}

export enum OrderSourceSubType {
  SUBSCRIPTION_VIEW = 'subscription_view',
}

export const PaymentService = {
  getCurrencyDetails: () => requests().get(`/payments/currency`),

  getOrders: (params: GetOrders | GetOrder) =>
    requests(config.paymentsServiceUrl).get('/payments/orders/', {
      params,
    }),

  getOrdersForExport: (params: GetOrdersForExport) =>
    requests(config.paymentsServiceUrl).get('/payments/orders/export', {
      params,
    }),

  recordOrderPayment: (orderId: string, params: RecordPaymentParams) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/orders/' + orderId + '/record-payment',
      params
    ),

  getSubscriptions: (params: GetSubscriptions | GetSubscription) =>
    requests(config.paymentsServiceUrl).get('/payments/subscriptions/', {
      params,
    }),

  getTransactions: (params: GetTransactions | GetTransaction) =>
    requests(config.paymentsServiceUrl).get('/payments/transactions/', {
      params,
    }),

  getTransactionsForExport: (params: GetTransactionsForExport) =>
    requests(config.paymentsServiceUrl).get('/payments/transactions/export', {
      params,
    }),

  getCoupons: (params: GetCoupons) =>
    requests(config.paymentsServiceUrl).get('/payments/coupon/list', {
      params,
    }),

  getCoupon: (params: GetCoupon) =>
    requests(config.paymentsServiceUrl).get('/payments/coupon', { params }),

  createCoupon: (params: CreateCoupon) =>
    requests(config.paymentsServiceUrl).post('/payments/coupon', params),

  updateCoupon: (params: UpdateCoupon) =>
    requests(config.paymentsServiceUrl).put('/payments/coupon', params),

  deleteCoupon: (params: DeleteCoupon) =>
    requests(config.paymentsServiceUrl).delete('/payments/coupon', {
      data: params,
    }),

  getStripeIntegration: (locationId: string) =>
    requests(config.paymentsServiceUrl).get(`/payments/integrations/stripe`, {
      params: { location_id: locationId },
    }),

  getTaxes: (params: BasicParams) =>
    requests(config.paymentsServiceUrl).get('/payments/taxes/', { params }),

  createTax: (params: CreateTax) =>
    requests(config.paymentsServiceUrl).post('/payments/taxes/', params),

  updateTax: (params: updateTax) =>
    requests(config.paymentsServiceUrl).put('/payments/taxes/', params),

  initiateStripeCustomerCardSetup: (params: InitiateStripeCustomerCardSetup) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/stripe/customer/initiate-card-setup',
      params
    ),

  verifyStripeCustomerCardSetup: (params: VerifyStripeCustomerCardSetup) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/stripe/customer/verify-card-setup',
      params
    ),

  cancelSubscriptions: (params: CancelSubscription) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/cancel',
      params
    ),

  updateSubscriptionTrialPeriod: (params: UpdateSubscriptionTrialPeriod) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/update-trial-period',
      params
    ),

  getAllIntegrations: (locationId: string) =>
    requests(config.paymentsServiceUrl).get(
      `/payments/integrations/${locationId}`
    ),

  getAllCustomerPaymentMethods: (params: GetCustomerPaymentMethods) =>
    requests(config.paymentsServiceUrl).get(
      '/payments/integrations/customer/payment-methods',
      { params }
    ),

  authorizeNetCardSaveAndSetup: (params: SetupAndSaveAuthorizeNetCard) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/authorize-net/customer/card-setup',
      params
    ),

  squareCardSaveAndSetup: (params: SetupAndSaveSquareCard) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/square/customer/card-setup',
      params
    ),

  nmiCardSaveAndSetup: (params: SetupAndSaveNMICard) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/nmi/customer/card-setup',
      params
    ),

  refundRequest: (params: RefundRequest) =>
    requests(config.paymentsServiceUrl).post('/payments/refunds', params),

  getTxnRefunds: (params: GetTxnRefunds) =>
    requests(config.paymentsServiceUrl).get('/payments/refunds', { params }),

  scheduleExportOrders: (params: GetOrdersForExport) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/orders/schedule-export',
      params
    ),

  scheduleExportTransactions: (params: GetTransactionsForExport) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/transactions/schedule-export',
      params
    ),

  scheduleExportSubscriptions: (params: ScheduleExportSubscriptions) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/schedule-export',
      params
    ),
  getPaymentSettings: (params: BasicParams) =>
    requests(config.paymentsServiceUrl).get('/payments/payment-settings', {
      params,
    }),
  updatePaymentSettings: (params: UpdatePaymentSettings) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/payment-settings',
      params
    ),
  getOrderFulfillments: (orderId: string, params: BasicParams) =>
    requests(config.paymentsServiceUrl).get(
      `/payments/orders/${orderId}/fulfillments`,
      {
        params,
      }
    ),
  createOrderFulfillments: (orderId: string, params: CreateFulfillment) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/orders/${orderId}/fulfillments`,
      params
    ),

  printShippingLabels: (params: PrintShippingLabels) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/orders/print-shipping-labels`,
      params
    ),

  connectAuthorizeNet: (params: AuthorizeNetConnect) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/authorize-net/connect',
      params
    ),
  disconnectAuthorizeNet: (params: DisconnectProvider) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/authorize-net/disconnect`,
      params
    ),
  connectNMI: (params: NMIConnect) =>
    requests(config.paymentsServiceUrl).post('/payments/nmi/connect', params),
  disconnectNMI: (params: DisconnectProvider) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/nmi/disconnect`,
      params
    ),
  getIntegrationsDetails: (locationId: string) =>
    requests(config.paymentsServiceUrl).get(
      `/payments/integrations/details/${locationId}`
    ),
  updateStripeAccountSettings: (
    locationId: string,
    enablePaymentElement: boolean
  ) =>
    requests(config.paymentsServiceUrl).post(`/payments/stripe/update`, {
      locationId,
      enablePaymentElement,
    }),
  connectStripe: (params: StripeConnect) =>
    requests(config.paymentsServiceUrl).get('/payments/stripe/connect', {
      params,
    }),
  disconnectStripe: (params: StripeDisconnect) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/stripe/disconnect`,
      params
    ),
  connectSquare: (params: SquareConnect) =>
    requests(config.paymentsServiceUrl).get('/payments/square/connect', {
      params,
    }),
  disconnectSquare: (params: SquareDisconnect) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/square/disconnect`,
      params
    ),
  getSquareIntegrationsDetails: (locationId: string) =>
    requests(config.paymentsServiceUrl).get(`/payments/integrations/square`, {
      params: {
        locationId,
      },
    }),
  connectPaypal: (params: PaypalConnectOptions) =>
    requests(config.paymentsServiceUrl).get('/payments/paypal/connect', {
      params,
    }),
  connectPaypalWithKey: (params: PaypalConnectWithKeyOptions) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/paypal/connect',
      params
    ),
  connectPaypalCallback: (params: any) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/paypal/callback',
      params
    ),
  disconnectPaypal: (params: GetPaypalAccounts) =>
    requests(config.paymentsServiceUrl).post(
      `/payments/paypal/disconnect`,
      params
    ),
  getConnectedProviders: (params: GetConnectedProvidersParams) =>
    requests(config.paymentsServiceUrl).get(
      `/payments/integrations/provider/connected`,
      { params }
    ),
  getAllProviders: (params: GetAllProvidersParams) =>
    requests(config.paymentsServiceUrl).get(`/payments/integrations/provider`, {
      params,
    }),
  getProvider: (params: GetProviderDetailParams) =>
    requests(config.paymentsServiceUrl).get(
      `/payments/integrations/provider/details`,
      {
        params,
      }
    ),
  connectWhitelabel: (providerId: string, params: ConnectWhiteLabelParams) =>
    requests(config.paymentsServiceUrl).put(
      `/payments/integrations/provider/whitelabel/${providerId}/connect`,
      params
    ),
  disconnectWhitelabel: (
    providerId: string,
    params: DisconnectWhiteLabelParams
  ) =>
    requests(config.paymentsServiceUrl).put(
      `/payments/integrations/provider/whitelabel/${providerId}/disconnect`,
      params
    ),
  setDefaultProvider: (params: DefaultProviderParams) =>
    requests(config.paymentsServiceUrl).put(
      'payments/integrations/provider/default',
      params
    ),
  updatePaymentIntegrationProvider: (params: UpdatePaymentProviderParams) =>
    requests(config.paymentsServiceUrl).put(
      'payments/integrations/provider',
      params
    ),
  generateLinkForUpdatingPaymentMethod: (params: SubscriptionIdParam) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/payment-method-link',
      params
    ),
  deactivateAllPaymentMethodLinksForASubscription: (
    params: SubscriptionIdParam
  ) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/payment-method-link/deactivate',
      params
    ),
  sendAPaymentMethodUpdateLinkInMail: (params: SubscriptionIdParam) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/payment-method-link/send-mail',
      params
    ),
  getAllTaxCategories: () =>
    requests(config.paymentsServiceUrl).get(`/payments/tax/automatic/category`),
  pauseSubscription: (params: PauseSubscriptionParam) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/pause',
      params
    ),
  resumeSubscription: (params: SubscriptionIdParam) =>
    requests(config.paymentsServiceUrl).post(
      '/payments/subscriptions/resume',
      params
    ),
  createSubscriptionSchedule: (params: CreateSubscriptionSchedule) =>
    requests(config.paymentsServiceUrl).post(
      `payments/subscriptions/create`,
      params
    ),
  createOrder: (params: any) =>
    requests(config.paymentsServiceUrl).post(`payments/orders`, params),
}
